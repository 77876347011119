import React, { useState, useRef, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Navbarside from "../component/Navbarside";
import Footer from "./footer";
import Moment from "moment";
import { LabTestOrder } from "../store/slices/lab";
import { useDownloadExcel } from "react-export-table-to-excel";
import Loader from "./Loader";
import { API_PATHS } from "../utils/constants/api.constants";

const LabTestOrders = () => {
  const tableRef = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate("");
  const fileName = "Appointment Analysis Report";
  const [data, setData] = useState([]);
  const [dropdownData, setDropdownData] = useState([]);
  const [responseCode, setResponseCode] = useState();
  const [categ, setCateg] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const fetchLabTestOrders = () => {
    setIsLoading(true);
    dispatch(LabTestOrder())
      .unwrap()
      .then((data) => {
        setIsLoading(false);
        console.log("LabTestOrders", data.user.data);
        if (data) {
          setData(data.user.data);
        }
      })
      .catch(({ message }) => {
        alert(message);
      });
  };

  useEffect(() => {
    fetchLabTestOrders();
    fetchData();
  }, []);

  const fetchData = () => {
    setIsLoading(true);
    axios
      .get(API_PATHS.getViewlabs)
      .then((postData) => {
        setIsLoading(false);
        if (postData?.data) {
          setDropdownData(postData?.data?.data);
        }
      })
      .catch(({ message }) => {
        alert(message);
      });
  };

  const handleFetchData = (e) => {
    e.preventDefault();
    if (!categ) {
      alert("Please select a category.");
      return;
    }
    setIsLoading(true);
    axios
      .get(`${API_PATHS.LabTestOrders}?labId=${categ}`)
      .then((postData) => {
        setIsLoading(false);
        console.log("postData", postData);
        if (postData?.data) {
          setData(postData?.data?.data);
        }
      })
      .catch(({ message }) => {
        alert(message);
      });
  };

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: "Appointment Analysis Report",
    sheet: "Sheet1",
  });

  return (
    <div>
      <Navbarside />
      {isLoading && <Loader />}

      <div className="container-fluid pb-5 response-cover">
        <div className="row">
          <div className="col-lg-2 col-md-4" />
          <div className="col-lg-10 col-md-8">
            <div className="container-fluid pt-5">
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-header">
                      <div className="card-title-wrap bar-success d-flex align-items-center">
                        <h4 className="card-title">Lab Orders</h4>
                      </div>
                    </div>
                    <section className="form-section">
                      <form onSubmit={handleFetchData}>
                        <div className="row">
                          <div className="col-md-3 col-sm-12 pt-2">
                            <p
                              className="col-black"
                              style={{ marginBottom: "2px" }}
                            >
                              Labs
                            </p>
                            <select
                              className="form-control"
                              onChange={(e) => setCateg(e.target.value)}
                              value={categ}
                            >
                              <option value="">Select Lab</option>
                              {dropdownData?.map((option) => (
                                <option value={option._id}>
                                  {option?.name}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div className="col-md-3 col-sm-12 pt-2 mt-3">
                            <button type="submit" className="btn btn-primary">
                              Submit
                            </button>
                          </div>
                          <div className="col-md-6 col-sm-12 pt-2 mt-3">
                            <button type="button" className="btn btn-primary" style={{float:"right"}} onClick={()=>navigate("/Labs/AddNewLabOrder")}>
                              Add New Order
                            </button>
                          </div>
                        </div>
                       
                      </form>
                    </section>

                    <div className="card-body collapse show">
                      <div className="card-block card-dashboard table-responsive">
                        {data !== "" && (
                          <div>
                            {/* <button
                              disabled={!data?.length}
                              onClick={onDownload}
                              className="btn btn-primary"
                            >
                              Export to Excel
                            </button> */}
                            {data?.length > 0 && (
                              <table
                                className="table table-striped table-bordered zero-configuration"
                                ref={tableRef}
                              >
                                <thead>
                                  <tr>
                                    <th>Test Order Id</th>
                                    <th>Lab Name</th>
                                    <th>Client Name</th>
                                    <th>Client Contact</th>
                                    <th>Test Name</th>
                                    <th>Lab Cost</th>
                                    <th>Sample Collection Date</th>
                                    <th>Status</th>
                                  </tr>
                                </thead>

                                <tbody>
                                  {data?.map((dataVal, index) => (
                                    <tr>
                                      <td>{dataVal.testOrderId}</td>

                                      <td>{dataVal?.labName}</td>

                                      <td>
                                        <p>{dataVal?.clientName}</p>
                                      </td>
                                      <td>{dataVal?.clientContact}</td>
                                      <td>{dataVal?.testName}</td>
                                      <td>{dataVal?.labCost}</td>
                                      <td>
                                        {" "}
                                        {Moment(
                                          dataVal?.sampleCollectionDate
                                        ).format("DD-MM-YYYY")}
                                      </td>
                                      <td style={{display:"flex",flexDirection:"column",gap:"10px"}}>
                                      <button
                                    // onClick={() =>
                                    //   enableApprove(LabVal._id)
                                    // }
                                    className={
                                      dataVal.isAdminApproved === false
                                        ? "btn btn-warning"
                                        : "btn btn-success"
                                    }
                                    style={{
                                      height: "25px",
                                      padding: "0 10px",
                                      cursor: "default",
                                    }}
                                  >
                                    {dataVal.isAdminApproved === false
                                      ? "Disable"
                                      : "Verified"}
                                  </button>
                                        <button
                                          key="edit"
                                          className="btn btn-primary"
                                          style={{
                                            height: "25px",
                                            padding: "0 10px",
                                          }}
                                          // onClick={(e) => {
                                          //   e.preventDefault();
                                          //   navigate(
                                          //     `/ViewDetails/${obj.ticketNumber || null}/${obj.flow}`
                                          //   );
                                          // }}
                                        >
                                          View Details
                                        </button>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            )}
                            {responseCode == 204 && (
                              <p>No session data found in this Date Range</p>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default LabTestOrders;
