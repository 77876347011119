import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { getPackage, DeletePackage } from "../../store/slices/package";
import { getAllExpertise } from "../../store/slices/hcexpert";
import Navbarside from "../Navbarside";
import Footer from "../footer";
import axios from 'axios';
import moment from 'moment';
import $ from "jquery";
import { confirmAlert } from "react-confirm-alert";

const Package = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [allExpertise, setExpertise] = useState([]);
  const [selectedExpertise, setSelectedExpertise] = useState(null);
  const [selectedSubExpertise, setSelectedSubExpertise] = useState(null);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  useEffect(() => {
    dispatch(getAllExpertise())
      .unwrap()
      .then((data) => setExpertise(data.allExpertise))
      .catch((error) => console.error("Failed to fetch expertise:", error.message));
  }, [dispatch]);

  useEffect(() => {
    loadPackages();
  }, [dispatch]);

  const loadPackages = () => {
    dispatch(getPackage({}))
      .unwrap()
      .then((data) => setTable(data.user.data))
      .catch((error) => console.error("Failed to fetch packages:", error.message));
  };

  const handleExpertiseChange = (e) => setSelectedExpertise(e.target.value);
  const handleSubExpertiseChange = (e) => setSelectedSubExpertise(e.target.value);

  const handleFilterSubmit = () => {
    const params = {
      expertiseId: selectedExpertise,
      subCategoryId: selectedSubExpertise,
      fromDate: startDate ? moment(startDate, 'YYYY-MM-DD').format('MM/DD/YYYY') : undefined,
      toDate: endDate ? moment(endDate, 'YYYY-MM-DD').format('MM/DD/YYYY') : undefined,
    };

    axios.get('https://api.healthonify.com/get/package', { params })
      .then((res) => setTable(res.data.data))
      .catch((error) => console.error("Failed to filter packages:", error.message));
  };

  const handleViewDetails = (id) => {
    navigate(`/packageDetails/${id}`);
  };

  const handleDelete = (id) => {
    confirmAlert({
      title: "Confirm Delete",
      message: "Are you sure you want to delete this package?",
      buttons: [
        {
          label: "Yes",
          onClick: () => deletePackage(id),
        },
        {
          label: "No",
        },
      ],
    });
  };

  const deletePackage = (id) => {
    console.log("newid",id)
    dispatch(DeletePackage(id))
      .unwrap()
      .then(() => loadPackages())
      .catch((error) => alert(error.message));
  };

  const setTable = (data) => {
    $("#example").DataTable({
      data: data.map((item, index) => ({
        index,
        ...item,
        category: item.expertiseId?.name,
        subCategory: item.subCategoryId?.name,
        session: item.sessionCount,
        duration: item.durationInDays,
      })),
      columns: [
        {
          title: "#",
          render: (data, type, row) => ++row.index,
        },
        { title: "Package Name", data: "name" },
        {
          title: "Category",
          render: (data, type, row) => row.category || "",
        },
        {
          title: "Sub Category",
          render: (data, type, row) => row.subCategory || "",
        },
        {
          title: "Session",
          render: (data, type, row) => row.session || "",
        },
        {
          title: "Duration",
          render: (data, type, row) => row.duration || "",
        },
        {
          title: "Status",
          render: (data, type, row) => {
            return `<button class="btn ${row.isActive ? "btn-success" : "btn-danger"}" style="height: 25px; padding: 0 10px">${row.isActive ? "Active" : "Inactive"}</button>`;
          },
        },
        {
          title: "Action",
          render: (data, type, row) => `
            <button
              class="btn btn-warning delete"
              data-id="${row._id}"
              style="height: 25px; padding: 0 10px"
            >
              Delete
            </button>
            <button
              class="btn btn-primary view-details"
              data-id="${row._id}"
              style="height: 25px; padding: 0 10px; margin-left: 5px;"
            >
              View Details
            </button>
            <a 
              href='/MembershipAnalysis?expertiseId=${row.expertiseId?._id}&subCategoryId=${row.subCategoryId?._id}&flow=${row.expertiseId.flowName}'
              class='btn btn-success'
              data-id="${row._id}"
              style="height: 25px; padding: 0 10px; margin-left: 5px;"
            >
              View Subscription
            </a>
          `,
        },
      ],
      destroy: true,
    });

    $("#example").on("click", ".delete", function () {
      const id = $(this).data("id");
      handleDelete(id);
    });

    $("#example").on("click", ".view-details", function () {
      const id = $(this).data("id");
      handleViewDetails(id);
    });
  };

  return (
    <div>
      <body data-col="2-columns" className=" 2-columns ">
        <Navbarside />

        <div className="container-fluid pb-5 response-cover">
          <div className="row">
            <div className="col-lg-2 col-md-4" />
            <div className="col-lg-10 col-md-8">
              <div className="container-fluid pt-5">
                <div className="row">
                  <div className="col-12">
                    <div className="card">
                      <div className="card-header">
                        <div className="card-title-wrap bar-success">
                          <h4 className="card-title">
                            Package &nbsp; &nbsp;
                            <Link to="/AddPackage" className="btn btn-primary">
                              <i className="fa fa-plus"></i> Add
                            </Link>
                          </h4>
                        </div>
                        <section className="form-section">
                          <div className="row">
                            <form className="row">
                              <div className="col-md-5 col-sm-12 pt-2">
                                <p className="col-black" style={{ marginBottom: "2px" }}>Category</p>
                                <select className="form-control" onChange={handleExpertiseChange} >
                                  <option value="">Select Expertise</option>
                                  {allExpertise && allExpertise.data && allExpertise.data.length
                                    ? allExpertise.data.map(option => {
                                      if (!option.parentExpertiseId || option.parentExpertiseId == null) {
                                        return (
                                          <option value={option._id}>
                                            {option.name}
                                          </option>
                                        )
                                      }
                                    })
                                    : ''
                                  }
                                </select>
                              </div>

                              <div className="col-md-5 col-sm-12 pt-2">
                                <p
                                  className="col-black"
                                  style={{ marginBottom: "2px" }}
                                >
                                  Sub Category
                                </p>
                                <select
                                  className="form-control"
                                  onChange={handleSubExpertiseChange}
                                >
                                  <option value="">Select Sub - Expertise</option>
                                  {selectedExpertise
                                    ? allExpertise.data.map(expertise => {
                                      if (expertise.parentExpertiseId == selectedExpertise) {
                                        return (
                                          <option value={expertise._id}>
                                            {expertise.name}
                                          </option>
                                        )
                                      }
                                    })
                                    : ''
                                  }
                                </select>
                              </div>

                            </form>
                            <div className="col-md-2 col-sm-12 pt-2 mt-3">

                              <button className="btn btn-primary" onClick={handleFilterSubmit}>
                                Apply Filter
                              </button>
                            </div>
                          </div>
                        </section>
                      </div>
                      <div className="card-body collapse show">
                        <div className="card-block card-dashboard table-responsive">
                          <table id="example" className="display"></table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </body>
    </div>
  );
};

export default Package;