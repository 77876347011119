import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Navbarside from './Navbarside'; // Import your Navbarside component
import Footer from './footer'; // Import your Footer component
import Loader from './Loader'; // Import your Loader component
import axios from 'axios';
import { ListGroup } from 'react-bootstrap';

const PackageInDetails = () => {
  const [labDetails, setLabDetails] = useState(null); // State to store API data
  const [isLoading, setIsLoading] = useState(true); // Loading state
  const [error, setError] = useState(null); // Error state
  const [activeTab, setActiveTab] = useState('packages');
  const [showModal, setShowModal] = useState(false);
  const [showModal1, setShowModal1] = useState(false);
  const [selectedDetails, setSelectedDetails] = useState(null);
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [bookingData, setBookingData] = useState(null);
  const [selectedTests, setSelectedTests] = useState([]);
  const [labName, setLabName] = useState('');
  const [testName, setTestName] = useState('');
  const [searchResults, setSearchResults] = useState({ labs: [], tests: [] });
  const [selectedLab, setSelectedLab] = useState(null);
  const [selectedTest, setSelectedTest] = useState(null);

  const existingData = JSON.parse(localStorage.getItem('labTestsData')) || {};
  console.log("existingData",existingData.labId )

  useEffect(() => {
    fetchLabDetails();
  }, []);

  // Call the API
  const fetchLabDetails = async () => {
    if (!existingData?.pinCode || !existingData?.date) {
      setError('Missing required parameters (pinCode, date, selectedLab, or selectedTest)');
      setIsLoading(false);
      return;
    }

    const formattedDate = new Date(existingData?.date).toLocaleDateString('en-US', {
      month: '2-digit',
      day: '2-digit',
      year: 'numeric',
    });

    // Construct the base API URL
    let apiUrl = `https://api-hc.healthonify.com/user/fetchLabTests?pinCode=${existingData?.pinCode}&date=${formattedDate}&labId=${existingData?.labId}`;

    // Conditionally add selectedLab and selectedTest to the API URL if they exist
    // if (selectedLab) {
    //   apiUrl += `&labbId=${selectedLab}`;
    // }

    // if (selectedTest) {
    //   apiUrl += `&labTestCategoryId=${selectedTest}`;
    // }

    try {
      const response = await fetch(apiUrl);
      if (!response.ok) {
        throw new Error('Failed to fetch lab details');
      }
      const data = await response.json();
      setLabDetails(data?.data);
    } catch (err) {
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleViewTestDetails = async (testItem) => {
    const url = `https://api-hc.healthonify.com/fetch/labPackageDetail?id=${testItem}`;

    try {
      setIsLoading(true); // Start loading
      setError(null); // Reset any previous errors
      const response = await axios.get(url);

      if (response?.data) {
        setSelectedDetails(response?.data?.data); // Update state with fetched details
        setShowModal(true);
        // console.log('Update', response?.data?.data);
      } else {
        setError('Failed to fetch details. Please try again.');
      }
    } catch (err) {
      console.error('Error fetching details:', err);
      setError('An error occurred. Please try again.');
    } finally {
      setIsLoading(false); // Stop loading
    }
  };

  const handlePackageSelect = (packageId) => {
    if (selectedPackage === packageId) {
      setSelectedPackage(null); // Deselect if clicked again
    } else {
      setSelectedPackage(packageId); // Select the new package
      setSelectedTests([]); // Deselect all tests when a package is selected
    }
  };
  
  // Handle selection of Individual Lab Test (multiple selection)
  const handleTestSelect = (testId) => {
    if (selectedTests.includes(testId)) {
      setSelectedTests(selectedTests.filter((id) => id !== testId)); // Deselect the test if already selected
    } else {
      setSelectedTests([...selectedTests, testId]); // Select the new test
      setSelectedPackage(null); // Deselect the package when a test is selected
    }
  };
  

  const handleBookLabTest = async () => {
    if (!selectedPackage && selectedTests.length === 0) {
      setError('Please select at least one package or test.');
      return;
    }

    const selectedData = {
      ...existingData,
      testId: selectedPackage || null,
      labTestCategoryIds: selectedTests,
      labId:existingData?.labId ? existingData?.labId :'6357be467efd830dee9769a6',
      source: 'admin',
    };

    try {
      setIsLoading(true);
      setError(null);

      const response = await axios.post('https://api-hc.healthonify.com/user/bookLabTest', selectedData, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response?.data) {
        alert('Lab test booked successfully!');
        setBookingData(response?.data?.data);
        setShowModal1(true);
        setSelectedPackage(null);
        setSelectedTests([]);
      } else {
        setError('Failed to book lab test. Please try again.');
      }
    } catch (err) {
      console.error('Error booking lab test:', err);
      setError('An error occurred. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleConfirmLabBook = async (ticketNumber, labTestId) => {
    if (!ticketNumber || !labTestId) {
      alert('Ticket number and Lab Test ID are required for confirmation.');
      return;
    }

    const requestData = {
      ticketNumber: ticketNumber,
      labTestId: labTestId,
    };

    try {
      setIsLoading(true);
      setError(null);

      const response = await axios.post('https://api-hc.healthonify.com/user/confirmLabBook', requestData, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response?.data) {
        setShowModal1(false);
        alert('Lab test booking confirmed successfully!');
      } else {
        setError(response?.data?.message || 'Failed to confirm lab test booking. Please try again.');
      }
    } catch (err) {
      setShowModal1(false);
      console.error('Error confirming lab booking:', err);
      setError('An error occurred while confirming the lab booking. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  // Trigger search when 3 or more characters are typed
  useEffect(() => {
    if (labName.length >= 3) {
      handleSearch('lab', labName); // Fetch lab data
    }
    if (testName.length >= 3) {
      handleSearch('test', testName); // Fetch test data
    }
  }, [labName, testName]);

  // Handle search and update state
  const handleSearch = async (type, query) => {
    try {
      if (type === 'lab') {
        const labResponse = await axios.get(`https://api.healthonify.com/search/Labs?name=${query}`);
        // console.log('data', labResponse.data);
        setSearchResults((prevState) => ({
          ...prevState,
          labs: labResponse?.data?.data || [],
        }));
      } else if (type === 'test') {
        const testResponse = await axios.get(`https://api.healthonify.com/search/LabTestCategory?name=${query}`);
        setSearchResults((prevState) => ({
          ...prevState,
          tests: testResponse?.data?.data || [],
        }));
      }
    } catch (error) {
      console.error('Error fetching search results:', error);
      alert('An error occurred while searching. Please try again.');
    }
  };

  const handleLabSelect = (lab) => {
    console.log('Selected lab:', lab._id); // This will log the selected lab data correctly
    setSelectedLab(lab._id); // Save the lab's ID
    setLabName(lab.name); // Set the input value to the selected lab name
    setSearchResults((prevState) => ({ ...prevState, labs: [] })); // Clear search results
  };

  const handleTestSelectsearch = (test) => {
    console.log('Selected test:', test._id); // This will log the selected test data correctly
    setSelectedTest(test._id); // Save the selected test
    setTestName(test.name); // Set the input value to the selected test name
    setSearchResults((prevState) => ({ ...prevState, tests: [] })); // Clear search results
  };

  return (
    <div>
      <Navbarside />
      {isLoading && <Loader />}
      <div className="container-fluid pb-5 response-cover">
        <div className="row">
          <div className="col-lg-2 col-md-4" />
          <div className="col-lg-10 col-md-8">
            <div className="container-fluid pt-5">
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-header">
                      <div className="card-title-wrap bar-success d-flex align-items-center">
                        <h4 className="card-title">Test Details</h4>
                      </div>
                    </div>
                    <div className="card-body collapse show">
                      <div className="card-block card-dashboard table-responsive">
                        {/* <div className="row">
                          <div className="col-lg-4 col-md-12 col-sm-12">
                            <input
                              type="text"
                              placeholder="Search Lab Name"
                              className="form-control"
                              value={labName}
                              onChange={(e) => setLabName(e.target.value)}
                              onFocus={() => setSearchResults((prevState) => ({ ...prevState, labs: [] }))}
                            />
                            {labName.length >= 3 && searchResults.labs.length > 0 && (
                              <div className="dropdown-menu show position-absolute w-100" style={{ zIndex: 1050 }}>
                                <ListGroup>
                                  {searchResults.labs.map((lab) => (
                                    <ListGroup.Item
                                      key={lab.id}
                                      action
                                      onClick={() => handleLabSelect(lab)}
                                      style={{ cursor: 'pointer', color: 'black' }}
                                    >
                                      {lab.name}
                                    </ListGroup.Item>
                                  ))}
                                </ListGroup>
                              </div>
                            )}
                          </div>

                          <div className="col-lg-4 col-md-12 col-sm-12">
                            <input
                              type="text"
                              placeholder="Search Tests Name"
                              className="form-control"
                              value={testName}
                              onChange={(e) => setTestName(e.target.value)}
                              onFocus={() => setSearchResults((prevState) => ({ ...prevState, tests: [] }))}
                            />
                            {testName.length >= 3 && searchResults.tests.length > 0 && (
                              <div className="dropdown-menu show position-absolute w-100" style={{ zIndex: 1050 }}>
                                <ListGroup>
                                  {searchResults.tests.map((test) => (
                                    <ListGroup.Item
                                      key={test.id}
                                      action
                                      onClick={() => handleTestSelectsearch(test)} 
                                      style={{ cursor: 'pointer', color: 'black' }}
                                    >
                                      {test.name}
                                    </ListGroup.Item>
                                  ))}
                                </ListGroup>
                              </div>
                            )}
                          </div>

                          <div className="col-lg-4 col-md-12 col-sm-12">
                            <button type="button" className="btn btn-info" onClick={fetchLabDetails}>
                              Submit
                            </button>
                          </div>
                        </div> */}
                        <div className="container mt-4">
                          <ul className="nav nav-tabs">
                            <li className="nav-item">
                              <button className={`nav-link ${activeTab === 'packages' ? 'active' : ''}`} onClick={() => setActiveTab('packages')}>
                                Lab Test Packages
                              </button>
                            </li>
                            <li className="nav-item">
                              <button className={`nav-link ${activeTab === 'tests' ? 'active' : ''}`} onClick={() => setActiveTab('tests')}>
                                Indivisual Lab Tests
                              </button>
                            </li>
                          </ul>

                          <div className="tab-content mt-3">
                            {activeTab === 'packages' && (
                              <div className="tab-pane fade show active" style={{ background: 'unset' }}>
                                {labDetails?.labTestPackages?.length > 0 ? (
                                  <div className="row">
                                    {labDetails?.labTestPackages?.map((packageItem, index) => (
                                      <div className="col-lg-12 col-md-12 col-sm-12 mb-3" key={index}>
                                        <div className="card">
                                          <div className="card-body d-flex justify-content-between align-items-center">
                                            {/* Left Section: Lab Test Package Details */}
                                            <div>
                                              <h6 className="card-title">{packageItem.name || 'N/A'}</h6>
                                              <p className="text-muted">Price: ₹{packageItem.price || 'N/A'}</p>
                                            </div>

                                            {/* Right Section: Checkbox and Button */}
                                            <div className="d-flex align-items-center">
                                              <input
                                                type="checkbox"
                                                className="form-check-input me-3 mb-3 "
                                                id={`checkbox-${index}`}
                                                checked={selectedPackage === packageItem._id}
                                                onChange={() => handlePackageSelect(packageItem._id)}
                                              />
                                              <button className="btn btn-primary btn-sm" onClick={() => handleViewTestDetails(packageItem?._id)}>
                                                View Details
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                ) : (
                                  <p>No Lab Test Packages available.</p>
                                )}
                              </div>
                            )}

                            {activeTab === 'tests' && (
                              <div className="tab-pane fade show active" style={{ background: 'unset' }}>
                                {labDetails?.labTests?.length > 0 ? (
                                  <div className="row">
                                    {labDetails?.labTests?.map((testItem, index) => (
                                      <div className="col-lg-12 col-md-12 col-sm-12 mb-3" key={index}>
                                        <div className="card">
                                          <div className="card-body d-flex justify-content-between align-items-center">
                                            {/* Left Section: Lab Test Details */}
                                            <div>
                                              <h6 className="card-title">{testItem.name || 'N/A'}</h6>
                                            </div>

                                            {/* Right Section: Checkbox and Button */}
                                            <div className="d-flex align-items-center">
                                              <input
                                                type="checkbox"
                                                className="form-check-input me-3 mb-3"
                                                id={`test-checkbox-${index}`}
                                                checked={selectedTests.includes(testItem._id)}
                                                onChange={() => handleTestSelect(testItem._id)}
                                              />
                                              <button className="btn btn-primary btn-sm" onClick={() => handleViewTestDetails(testItem?._id)}>
                                                View Details
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                ) : (
                                  <p>No Lab Tests available.</p>
                                )}
                              </div>
                            )}
                          </div>
                        </div>

                        <div className={`modal fade ${showModal ? 'show d-block' : ''}`} tabIndex="-1" style={{ backgroundColor: 'rgba(0,0,0,0.5)' }}>
                          <div className="modal-dialog modal-lg">
                            <div className="modal-content">
                              <div className="modal-header">
                                <h5 className="modal-title">Package Information</h5>
                                <button type="button" className="btn-close" onClick={() => setShowModal(false)}></button>
                              </div>
                              <div
                                className="modal-body"
                                style={{
                                  maxHeight: '400px', // Set a maximum height for the modal body
                                  overflowY: 'scroll', // Enable vertical scrolling
                                }}
                              >
                                {selectedDetails?.pacakgeDetails?.length > 0 ? (
                                  <div className="mb-3">
                                    {selectedDetails.pacakgeDetails.map((packageItem) => (
                                      <div key={packageItem._id} className="mb-2">
                                        <p>
                                          <strong>Name:</strong> {packageItem.name}
                                        </p>
                                        <p>
                                          <strong>Price:</strong> ₹{packageItem.price}
                                        </p>
                                        <p>
                                          <strong>Description:</strong> {packageItem.description}
                                        </p>
                                      </div>
                                    ))}
                                  </div>
                                ) : (
                                  <p>No package details available.</p>
                                )}

                                {/* Lab Tests */}
                                <h6>Lab Tests</h6>
                                {selectedDetails?.labTests?.length > 0 ? (
                                  <ul>
                                    {selectedDetails.labTests.map((test) => (
                                      <li key={test._id}>
                                        <strong>{test.name}</strong>
                                      </li>
                                    ))}
                                  </ul>
                                ) : (
                                  <p>No lab tests available.</p>
                                )}
                              </div>
                              <div className="modal-footer">
                                <button className="btn btn-secondary" onClick={() => setShowModal(false)}>
                                  Close
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className={`modal fade ${showModal1 ? 'show d-block' : ''}`} tabIndex="-1" style={{ backgroundColor: 'rgba(0,0,0,0.5)' }}>
                          <div className="modal-dialog modal-lg">
                            <div className="modal-content">
                              <div className="modal-header">
                                <h5 className="modal-title">Confirm Lab Booking Details</h5>
                                <button type="button" className="btn-close" onClick={() => setShowModal1(false)}></button>
                              </div>
                              <div
                                className="modal-body"
                                style={{
                                  maxHeight: '400px', // Set a maximum height for the modal body
                                  overflowY: 'scroll', // Enable vertical scrolling
                                }}
                              >
                                {/* Ticket Number */}
                                <p>
                                  <strong>Ticket Number:</strong> {bookingData?.ticketNumber || 'N/A'}
                                </p>

                                {/* Amount */}
                                <p>
                                  <strong>Amount:</strong> ₹{bookingData?.amount || 'N/A'}
                                </p>
                              </div>
                              <div className="modal-footer">
                                {/* Confirm Booking Button */}
                                <button className="btn btn-primary" onClick={() => handleConfirmLabBook(bookingData?.ticketNumber, bookingData?.labTestId)}>
                                  Confirm Booking
                                </button>
                                <button className="btn btn-secondary" onClick={() => setShowModal1(false)}>
                                  Close
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <button className="btn btn-primary" onClick={handleBookLabTest}>
                          Book Test
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default PackageInDetails;
