import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Navbarside from './Navbarside';
import axios from 'axios';
import debounce from 'lodash.debounce';
import Footer from './footer';
import { addfitnesscenter } from '../store/slices/fitness';
import Loader from './Loader';

const AddNewLabOrder = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [query, setQuery] = useState('Agilus');
  const [results, setResults] = useState([]);
  const [formState, setFormState] = useState({
    age: '',
    date: '',
    testType: '',
    collectionType: '',
    collectionDate: '',
    firstName: '',
    lastName: '',
    dob: '',
    gender: '',
    mobileNo: '',
    email: '',
    address: '',
    area: '',
    city: '',
    state: '',
    pinCode: '',
    labId: '',
  });
  console.log(formState);

  const [formErrors, setFormErrors] = useState({});
  const [apiData, setApiData] = useState({}); // State to store API responses
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setFormState((prevState) => {
      // Format date input as MM-DD-YYYY
      if (name === 'date' || name === 'collectionDate' || name === 'dob') {
        const date = new Date(value);

        // Extract the parts of the date (MM-DD-YYYY format)
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        const year = date.getFullYear();

        // Return date in MM-DD-YYYY format
        const formattedDate = `${month}-${day}-${year}`;
        return { ...prevState, [name]: formattedDate };
      }

      if (name === 'searchLab') {
        setQuery(value);
        fetchLabs(value);
      }

      // Update other fields normally
      return { ...prevState, [name]: value };
    });

    // Clear error for the field being updated
    setFormErrors((prevState) => ({ ...prevState, [name]: '' }));
  };

  const getFormattedDate = (date) => {
    const d = new Date(date);

    // Get year, month, and day with leading zeros where necessary
    const year = d.getFullYear();
    const month = (d.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-indexed, so we add 1
    const day = d.getDate().toString().padStart(2, '0');

    // Return the date in YYYY-MM-DD format
    return `${year}-${month}-${day}`;
  };

  const handleSlotSelection = (slot) => {
    setSelectedSlot(slot);
    const existingData = JSON.parse(localStorage.getItem('labTestsData')) || {};
    const updatedData = {
      ...existingData,
      collectionTime: slot,
      userId: apiData?.userDetails?.data?.[0]?._id,
      labbId:apiData?.labTests?.data?.message
    };
    localStorage.setItem('labTestsData', JSON.stringify(updatedData));
  };

  // Validate inputs
  const validateInputs = () => {
    const errors = {};
    const { firstName, lastName, dob, gender, mobileNo, email, pinCode } = formState;

    if (!firstName.trim()) errors.firstName = 'First Name is required.';
    if (!lastName.trim()) errors.lastName = 'Last Name is required.';
    if (!dob.trim()) errors.dob = 'Date of Birth is required.';
    if (!gender.trim()) errors.gender = 'Gender is required.';

    if (!mobileNo.trim()) {
      errors.mobileNo = 'Mobile Number is required.';
    } else if (!/^\d{10}$/.test(mobileNo)) {
      errors.mobileNo = 'Please enter a valid 10-digit mobile number.';
    }

    if (!pinCode.trim()) {
      errors.pinCode = 'Pin Code is required.';
    } else if (!/^\d{6}$/.test(pinCode)) {
      errors.pinCode = 'Please enter a valid 6-digit pin code.';
    }

    if (!email.trim() || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      errors.email = 'Please enter a valid email.';
    }

    setFormErrors(errors);
    return Object.keys(errors).length === 0; // Return true if no errors
  };

  // API request handler
  const callApis = async () => {
    const formattedDate = new Date(formState.date).toLocaleDateString('en-US', {
      month: '2-digit',
      day: '2-digit',
      year: 'numeric',
    });

    const api1 = `https://api-hc.healthonify.com/user/searchLabs?pinCode=${formState?.pinCode}&date=${formattedDate}&collectionType=${formState?.collectionType}&labId=${formState?.labId}`;
    const api2 = `https://api.healthonify.com/get/user?mobileNo=${formState?.mobileNo}`;

    try {
      const [response1, response2] = await Promise.all([fetch(api1).then((res) => res.json()), fetch(api2).then((res) => res.json())]);

      // Save responses to local storage
      localStorage.setItem('labTestsData', JSON.stringify(formState));
      // localStorage.setItem('labTests', JSON.stringify(response1));
      // localStorage.setItem('userDetails', JSON.stringify(response2));

      // Update state with API responses
      setApiData({ labTests: response1, userDetails: response2 });

      return { response1, response2 };
    } catch (error) {
      console.error('Error fetching APIs:', error);
      throw error;
    }
  };

  // Submit handler
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateInputs()) return;

    const payload = {
      ...formState,
      age: new Date().getFullYear() - new Date(formState.dob).getFullYear(), // Calculate age from DOB
    };

    try {
      const { response1, response2 } = await callApis();

      // Dispatch the fitness center data along with API responses
      setIsLoading(true);
      dispatch(addfitnesscenter({ ...payload, labTests: response1, userDetails: response2 }))
        .unwrap()
        .then(() => {
          setIsLoading(false);
          setShowModal(true);
          console.log('api call that');
        });
    } catch (error) {
      setIsLoading(false);
      alert('Failed to fetch data or submit order.');
      console.error(error);
    }
  };

  const handleSubmitTimeSlots = async () => {
    navigate('/Labs/PackageDetails');
  };

  const fetchLabs = debounce(async (searchTerm) => {
    if (searchTerm.length >= 3) {
      try {
        const response = await axios.get(`https://api.healthonify.com/search/Labs?name=${searchTerm}`);
        setResults(response?.data?.data); // Assuming the API returns an array of lab objects
      } catch (error) {
        console.error('Error fetching labs:', error);
      }
    } else {
      setResults([]); // Clear results if fewer than 3 characters
    }
  }, 300);

  const handleSelectLab = (lab) => {
    console.log(lab._id);
    setQuery(lab.name); // Update input with selected lab name
    setFormState((prevState) => ({ ...prevState, labId: lab._id }));
    setResults([]);
  };

  return (
    <body data-col="2-columns" className="2-columns">
      <Navbarside />
      {isLoading && <Loader />}
      <div className="container-fluid pb-5 response-cover">
        <div className="row">
          <div className="col-lg-2 col-md-4" />
          <div className="col-lg-10 col-md-8">
            <div className="container-fluid pt-5">
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-header">
                      <div className="card-title-wrap bar-danger d-flex align-items-center">
                        <h4 className="card-title">Add New Lab Order</h4>
                      </div>
                    </div>
                    <section className="form-section">
                      <form onSubmit={handleSubmit}>
                        <div className="row">
                          <div className="col-md-6">
                            <label htmlFor="date">Search lab</label>
                            <input type="text" id="searchLab" name="searchLab" value={query} onChange={handleInputChange} className="form-control" placeholder="Type lab name..." />
                            {results?.length > 0 && (
                              <ul className="dropdown-menu scrollable-dropdown">
                                {results?.map((lab) => (
                                  <li key={lab._id} className="dropdown-item" onClick={() => handleSelectLab(lab)}>
                                    {lab.name}
                                  </li>
                                ))}
                              </ul>
                            )}
                          </div>
                          <div className="col-md-6">
                            <label htmlFor="date">Date</label>
                            <input type="date" name="date" value={getFormattedDate(formState.date)} onChange={handleInputChange} className="form-control" required />
                          </div>

                          <div className="col-md-6">
                            <label htmlFor="testType">Test Type</label>
                            <select name="testType" value={formState.testType} onChange={handleInputChange} className="form-control" required>
                              <option value="">Select Test Type</option>
                              <option value="package">Package</option>
                              <option value="individual">Individual</option>
                            </select>
                          </div>

                          <div className="col-md-6">
                            <label htmlFor="collectionType">Collection Type</label>
                            <select name="collectionType" value={formState.collectionType} onChange={handleInputChange} className="form-control" required>
                              <option value="">Select Collection Type</option>
                              <option value="home">Home Pickup</option>
                              <option value="lab">Lab Visit</option>
                            </select>
                          </div>

                          <div className="col-md-6">
                            <label htmlFor="collectionDate">Collection Date</label>
                            <input type="date" name="collectionDate" value={getFormattedDate(formState.collectionDate)} onChange={handleInputChange} className="form-control" required />
                          </div>

                          {/* <div className="col-md-6">
                            <label htmlFor="collectionTime">Collection Time</label>
                            <input
                              type="time"
                              name="collectionTime"
                              value={formState.collectionTime}
                              onChange={handleInputChange}
                              className="form-control"
                              required
                            />
                          </div> */}

                          <div className="col-md-6">
                            <label htmlFor="firstName">Age</label>
                            <input type="text" name="age" value={formState.age} onChange={handleInputChange} className="form-control" required />
                            <small className="text-danger">{formErrors.firstName}</small>
                          </div>

                          <div className="col-md-6">
                            <label htmlFor="firstName">First Name</label>
                            <input type="text" name="firstName" value={formState.firstName} onChange={handleInputChange} className="form-control" required />
                            <small className="text-danger">{formErrors.firstName}</small>
                          </div>

                          <div className="col-md-6">
                            <label htmlFor="lastName">Last Name</label>
                            <input type="text" name="lastName" value={formState.lastName} onChange={handleInputChange} className="form-control" required />
                            <small className="text-danger">{formErrors.lastName}</small>
                          </div>

                          <div className="col-md-6">
                            <label htmlFor="dob">Date of Birth</label>
                            <input type="date" name="dob" value={getFormattedDate(formState.dob)} onChange={handleInputChange} className="form-control" required />
                            <small className="text-danger">{formErrors.dob}</small>
                          </div>

                          <div className="col-md-6">
                            <label htmlFor="gender">Gender</label>
                            <select name="gender" value={formState.gender} onChange={handleInputChange} className="form-control" required>
                              <option value="">Select Gender</option>
                              <option value="Male">Male</option>
                              <option value="Female">Female</option>
                              <option value="Other">Other</option>
                            </select>
                            <small className="text-danger">{formErrors.gender}</small>
                          </div>

                          <div className="col-md-6">
                            <label htmlFor="mobileNo">Mobile Number</label>
                            <input type="text" name="mobileNo" value={formState.mobileNo} onChange={handleInputChange} className="form-control" required />
                            <small className="text-danger">{formErrors.mobileNo}</small>
                          </div>

                          <div className="col-md-6">
                            <label htmlFor="email">Email</label>
                            <input type="email" name="email" value={formState.email} onChange={handleInputChange} className="form-control" required />
                            <small className="text-danger">{formErrors.email}</small>
                          </div>
                          <div className="col-md-6">
                            <label htmlFor="pinCode">PinCode</label>
                            <input type="text" name="pinCode" value={formState.pinCode} onChange={handleInputChange} className="form-control" required />
                            <small className="text-danger">{formErrors.email}</small>
                          </div>

                          {/* Additional Address Fields */}
                          {formState.collectionType === 'home' && (
                            <>
                              <div className="col-md-6">
                                <label htmlFor="address">Address</label>
                                <input type="text" name="address" value={formState.address} onChange={handleInputChange} className="form-control" required />
                              </div>
                              <div className="col-md-6">
                                <label htmlFor="area">Area</label>
                                <input type="text" name="area" value={formState.area} onChange={handleInputChange} className="form-control" required />
                              </div>
                              <div className="col-md-6">
                                <label htmlFor="city">City</label>
                                <input type="text" name="city" value={formState.city} onChange={handleInputChange} className="form-control" required />
                              </div>
                              <div className="col-md-6">
                                <label htmlFor="state">State</label>
                                <input type="text" name="state" value={formState.state} onChange={handleInputChange} className="form-control" required />
                              </div>
                            </>
                          )}

                          {
                            <div className={`modal fade ${showModal ? 'show d-block' : ''}`} tabIndex="-1" style={{ backgroundColor: 'rgba(0,0,0,0.5)' }}>
                              <div className="modal-dialog">
                                <div className="modal-content">
                                  <div className="modal-header">
                                    <h5 className="modal-title">Available Slots</h5>
                                    <button type="button" className="btn-close" onClick={() => setShowModal(false)}></button>
                                  </div>
                                  <div className="modal-body">
                                    <div className="d-flex flex-wrap">
                                      {apiData?.labTests?.data?.map((slot) => (
                                        <button
                                          key={slot?.SLOTID}
                                          type="button"
                                          className={`btn slot-btn m-1 ${selectedSlot === slot?.SLOTS ? 'btn-primary text-white' : 'btn-outline-primary'}`}
                                          onClick={() => handleSlotSelection(slot?.SLOTS)}
                                        >
                                          {slot?.SLOTS}
                                        </button>
                                      ))}
                                    </div>
                                  </div>
                                  <div className="modal-footer">
                                    <button className="btn btn-success" onClick={() => handleSubmitTimeSlots(selectedSlot)} disabled={!selectedSlot}>
                                      Submit
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          }

                          <div className="col-md-12  mt-4">
                            <button type="submit" className="btn btn-primary">
                              Submit
                            </button>
                          </div>
                        </div>
                      </form>
                    </section>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </body>
  );
};

export default AddNewLabOrder;
