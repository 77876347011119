import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// import hcPackage from "../../services/hcPackage.service";
import fitnessPackage from "../../services/fitnessPackage.service";
import AuthService from "../../services/auth.service";

export const getFitnessPackage = createAsyncThunk(
  "/package?flow=fitness/get",
  async (thunkAPI) => {
    try {
      const data = await fitnessPackage.getFitnessPackage();
      return { user: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);

// export const postWmPackage = createAsyncThunk(
//   "wmPackage/post",
//   async (item, thunkAPI) => {
//     try {
//       const data = await wmPackageService.postWmPackage(item);
//       return { user: data };
//     } catch (error) {
//       const message =
//         (error.response &&
//           error.response.data &&
//           error.response.data.message) ||
//         error.message ||
//         error.toString();
//       return thunkAPI.rejectWithValue({ message });
//     }
//   }
// );

// export const editWmPackage = createAsyncThunk(
//   "wmPackage/put",
//   async (item, thunkAPI) => {
//     try {
//       const data = await wmPackageService.editWmPackage(item);
//       return { user: data };
//     } catch (error) {
//       const message =
//         (error.response &&
//           error.response.data &&
//           error.response.data.message) ||
//         error.message ||
//         error.toString();
//       return thunkAPI.rejectWithValue({ message });
//     }
//   }
// );

// export const deleteWmPackage = createAsyncThunk(
//   "wmPackage/delete",
//   async (thunkAPI) => {
//     try {
//       const data = await wmPackageService.deleteWmPackage();
//       return { user: data };
//     } catch (error) {
//       const message =
//         (error.response &&
//           error.response.data &&
//           error.response.data.message) ||
//         error.message ||
//         error.toString();
//       return thunkAPI.rejectWithValue({ message });
//     }
//   }
// );

const initialState = {
  loading: false,
  error: "",
  user: AuthService.getUserDetails() || null,
  isLoggedIn: false,
};

const FitnessPackage = createSlice({
  name: "fitnessPackage",
  initialState,
  extraReducers: {
    [getFitnessPackage.pending]: (state) => {
      state.loading = true;
      state.error = "";
      state.isLoggedIn = false;
      state.user = null;
    },
    [getFitnessPackage.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = "";
      state.isLoggedIn = true;
      state.user = action.payload.user;
    },
    [getFitnessPackage.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
      state.isLoggedIn = false;
      state.user = null;
    },

    // [postWmPackage.pending]: (state) => {
    //   state.loading = true;
    //   state.error = "";
    //   state.isLoggedIn = false;
    //   state.user = null;
    // },
    // [postWmPackage.fulfilled]: (state, action) => {
    //   state.loading = false;
    //   state.error = "";
    //   state.isLoggedIn = true;
    //   state.user = action.payload.user;
    // },
    // [postWmPackage.rejected]: (state, action) => {
    //   state.loading = false;
    //   state.error = action.payload.message;
    //   state.isLoggedIn = false;
    //   state.user = null;
    // },

    // [editWmPackage.pending]: (state) => {
    //   state.loading = true;
    //   state.error = "";
    //   state.isLoggedIn = false;
    //   state.user = null;
    // },
    // [editWmPackage.fulfilled]: (state, action) => {
    //   state.loading = false;
    //   state.error = "";
    //   state.isLoggedIn = true;
    //   state.user = action.payload.user;
    // },
    // [editWmPackage.rejected]: (state, action) => {
    //   state.loading = false;
    //   state.error = action.payload.message;
    //   state.isLoggedIn = false;
    //   state.user = null;
    // },

    // [deleteWmPackage.pending]: (state) => {
    //   state.loading = true;
    //   state.error = "";
    //   state.isLoggedIn = false;
    //   state.user = null;
    // },
    // [deleteWmPackage.fulfilled]: (state, action) => {
    //   state.loading = false;
    //   state.error = "";
    //   state.isLoggedIn = true;
    //   state.user = action.payload.user;
    // },
    // [deleteWmPackage.rejected]: (state, action) => {
    //   state.loading = false;
    //   state.error = action.payload.message;
    //   state.isLoggedIn = false;
    //   state.user = null;
    // },
  },
});

const { reducer } = fitnessPackage;
export default reducer;
