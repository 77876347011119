import axios from "axios";
import { API_PATHS, API_BASE_URL } from "../utils/constants/api.constants";

class PackageService {
  static getPackages = (params) => {
    try {
      return axios.get(
        `${API_BASE_URL}get/package`,
        { params }
      ).then(res => res.data);
      ;
    } catch (e) {
      console.log(e.message)
      throw e
    }
  }


  static postPackages(item) {
    return axios.post(API_PATHS.addPackage, item).then((response) => response.data).catch(err => err.message);
  }

  static deletePackage(id) {
    console.log("delete1",id)
    let api = '';
    api = API_PATHS.deletePackage + '?id=' + id;
    localStorage.removeItem("id");
    return axios
      .delete(api)
      .then((response) => {
        if (response.data) {
          // localStorage.setItem("user", JSON.stringify(response.data));
        }
        return response.data;
      });
  }

  static removeUserDetails() {
    localStorage.removeItem("user");
  }

  static getUserDetails() {
    return JSON.parse(localStorage.getItem("user"));
  }
}

export default PackageService; 