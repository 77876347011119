import React, { useState, useRef, useEffect } from "react";
import { useDispatch } from "react-redux";
import Navbarside from "../../component/Navbarside";
import Footer from "../footer";
import Moment from "moment";
import { getSessions } from "../../store/slices/analysis";
import { useDownloadExcel } from "react-export-table-to-excel";
import { useLocation, useParams } from "react-router-dom";
import { get } from 'axios';
import { API_BASE_URL, API_PATHS } from "../../utils/constants/api.constants";
import { limitForPagination } from "../../utils/appUtils";


const ListSessions = () => {
  const location = useLocation();
  const [params, setParams] = useState({});
  const [sessions, setSessions] = useState([]);
  const [row, setRow] = useState([]);
  const tableRef = useRef(null);
  const dispatch = useDispatch();
  const { ticketNumber, flow } = useParams()
  const searchParams = new URLSearchParams(location.search);

  useEffect(() => {
    fetData()
  }, [dispatch,]);
  const fetData = (page = 0) => {
    const ticketNumber = searchParams.get('ticketNumber');
    const flow = searchParams.get('flow');
    const limitOptions = { page: page, limit: limitForPagination }
    // let paramsNew = { ...params, ...limitOptions }
    let paramsNew = { ...params }
    if (ticketNumber) paramsNew.ticketNumber = ticketNumber
    if (flow) paramsNew.flow = flow
    setParams(paramsNew)
    // dispatch(getSessions(paramsNew)).unwrap().then((sessions) => {
    //   setSessions(sessions.data.data)
    // }).catch(({ message }) => {
    //   // alert(message);
    // });
    console.log({ paramsNew })
    get(API_BASE_URL + 'fetch/session', { params: paramsNew }).then(res => {
      setSessions(res.data.data)
      const rowData = res?.data?.data?.map((dataVal) => {
        let newObj = {
          Date: ` ${Moment(dataVal.startDate).format("DD-MM-YYYY")}- ${Moment(
            dataVal.startDate
          ).format("hh:mm A")}`,
          'Session number': dataVal.sessionNumber,
          'Package Name': dataVal?.packageId?.name,
          'Category': dataVal?.packageId?.expertiseId?.name,
          'Bill Amount': dataVal?.paymentId?.netAmount,
          'Status': dataVal?.status,

        };
        return newObj
      });
      setRow(rowData)
    }).catch(err => {
      alert(err.message)
    })
  }

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: "Membership Analysis Report",
    sheet: "Sheet1",
  });

  return (
    <div>
      <Navbarside />

      <div className="container-fluid pb-5 response-cover">
        <div className="row">
          <div className="col-lg-2 col-md-4" />
          <div className="col-lg-10 col-md-8">
            <div className="container-fluid pt-5">
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-header">
                      <div className="card-title-wrap bar-success d-flex align-items-center">
                        <h4 className="card-title">Sessions</h4>
                        {/* &nbsp;
                        <ExportToExcel apiData={data} fileName={fileName} /> */}
                      </div>
                    </div>
                    <div className="card-body collapse show">
                      <div className="card-block card-dashboard table-responsive">
                        {sessions && sessions.length && (
                          <div >

                            <table
                              className="table table-striped table-bordered zero-configuration"
                              ref={tableRef}
                            >
                              <thead>
                                <tr>
                                  <th>Date</th>
                                  <th>Session number</th>
                                  <th>Package Name</th>
                                  <th>Category</th>
                                  {/* <th>Active / Inactive</th> */}
                                  <th>Bill Amount</th>
                                  <th>Status</th>
                                </tr>
                              </thead>

                              <tbody>
                                {sessions && sessions.length
                                  ? sessions.map((dataVal, index) => (
                                    <tr>
                                      <td>
                                        {dataVal.startDate && (
                                          <>
                                            {Moment(dataVal.startDate).format(
                                              "DD-MM-YYYY"
                                            )} {Moment(dataVal.startDate).format(
                                              "hh:mm A"
                                            )}
                                          </>
                                        )}

                                      </td>
                                      <td>{dataVal.sessionNumber}</td>
                                      <td>
                                        {dataVal?.packageId?.name}
                                      </td>
                                      <td>{dataVal?.packageId?.expertiseId?.name}</td>
                                      {/* <td>{dataVal.isActive ? 'Yes' : 'Yes'}</td> */}
                                      <td>{dataVal?.paymentId?.netAmount}</td>
                                      <td>{dataVal.status}</td>
                                    </tr>
                                  ))
                                  : ''
                                }
                              </tbody>
                            </table>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div >

      <Footer />
    </div >
  );
};

export default ListSessions;
