import React from "react";
import Navbarside from "./Navbarside";
import { useDispatch } from "react-redux";
import { getVendorfitcenter } from "../store/slices/fitness";
import { useState } from "react";
import Footer from "./footer";
import { useNavigate, useParams, Link } from "react-router-dom";

import { ExportToExcel } from "./ExportToExcel";
import { API_PATHS } from "../utils/constants/api.constants";
import axios from "axios";

const MyFitnessCenter = () => {
  const [fitnessenquire, setFitnessenquire] = useState([]);

  const dispatch = useDispatch();

  const [data, setData] = useState([]);
  const fileName = "Fitness Center";

  React.useEffect(() => {
    const fetchData = () => {
      axios.get(API_PATHS.getfitnesscenter).then((postData) => {
        const customHeadings = postData.data.data.map((item) => ({
          "Fitness Name": item.fcName,
          "Mobile No.": item.fcMobileNo,
          Email: item.fcEmail,
          City: item.fcAddress.city,
          State: item.fcAddress.state,
        }));

        setData(customHeadings);
      });
    };
    fetchData();
  }, []);

  React.useEffect(() => {
    dispatch(getVendorfitcenter())
      .unwrap()
      .then((data) => {
        setFitnessenquire(data.user.data);
      })
      .catch(({ message }) => {
        alert(message);
      });
  }, [dispatch]);

  return (
    <div>
      <Navbarside />

      <div className="container-fluid response-cover pb-5">
        <div className="row">
          <div className="col-lg-2 col-md-4" />
          <div className="col-lg-10 col-md-8">
            <div className="container-fluid pt-5">
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-header">
                      <div className="card-title-wrap bar-success d-flex align-items-center">
                        <h4 className="card-title">Fitness Center </h4>
                        &nbsp; &nbsp; &nbsp;
                        <Link
                          to="/Fitness/FitnessCenter/AddFitnessCenter"
                          className="btn btn-success col-white"
                          style={{ marginBottom: "0%" }}
                        >
                          List New
                        </Link>
                      </div>
                    </div>
                    <div className="card-body collapse show">
                      <div className="card-block card-dashboard table-responsive">
                        <table className="table table-striped table-bordered zero-configuration">
                          <thead>
                            <tr>
                              <th>Name</th>
                              <th>Email</th>

                              <th>Mobile No.</th>
                              <th>About</th>

                              <th>Address</th>
                              <th>Status</th>
                              <th>Plans</th>
                            </tr>
                          </thead>

                          <tbody>
                            {fitnessenquire.map((fitnessVal, index) => (
                              <tr key={index}>
                                <td>{fitnessVal.fcName}</td>
                                <td>{fitnessVal.fcEmail}</td>
                                <td>{fitnessVal.fcMobileNo}</td>
                                <td>{fitnessVal.about.substring(0, 60)}</td>
                                <td>
                                  {fitnessVal.fcAddress.city},{" "}
                                  {fitnessVal.fcAddress.state}
                                </td>
                                <td>
                                  {fitnessVal.status === "approvalPending" && (
                                    <button
                                      className="btn btn-warning"
                                      style={{
                                        height: "25px",
                                        padding: "0 10px",
                                      }}
                                    >
                                      Approval Pending
                                    </button>
                                  )}

                                  {fitnessVal.status === "rejected" && (
                                    <button
                                      className="btn btn-danger"
                                      style={{
                                        height: "25px",
                                        padding: "0 10px",
                                      }}
                                    >
                                      Rejected
                                    </button>
                                  )}

                                  {fitnessVal.status === "approved" && (
                                    <button
                                      className="btn btn-success"
                                      style={{
                                        height: "25px",
                                        padding: "0 10px",
                                      }}
                                    >
                                      Approved
                                    </button>
                                  )}
                                </td>
                                <td>
                                  {fitnessVal.status === "approved" && (
                                    <Link
                                      className="btn btn-success"
                                      style={{
                                        height: "25px",
                                        padding: "0 10px",
                                      }}
                                      to={`/AddPackage`}
                                    >
                                      Add Plan
                                    </Link>
                                  )}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default MyFitnessCenter;
