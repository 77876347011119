import { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import Navbarside from "../../component/Navbarside";
import { generalEnquiry } from "../../store/slices/enquiry";
import Moment from "moment";
import Footer from "../footer";
import Table from "../common/Table";

import { API_PATHS } from "../../utils/constants/api.constants";
import axios from "axios";
import $ from "jquery";
import Loader from "../Loader";
import { useLocation, useNavigate } from "react-router-dom";
import { useDownloadExcel } from "react-export-table-to-excel";
import { utils, writeFile } from "xlsx";
import Pagination from "../common/Pagination";
import { excelDownload } from "../../utils/appUtils";

const GeneralEnquiry = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [flow, setFlow] = useState("");
  const [source, setSource] = useState("healthonify");
  const [url, setUrl] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [page, setpage] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  // const [queryParam, setQueryParam] = useState({})
  const queryParams = useRef({});
  const [column, setColumn] = useState([
    { isHtml: false, name: "#" },
    { isHtml: true, name: "User Details" },
    { isHtml: false, name: "Message" },
    { isHtml: false, name: "Category" },
    { isHtml: false, name: "Date of Enquiry" },
    { isHtml: false, name: "Status" },
    { isAction: true, name: "Action" },
  ]);
  const [row, setRow] = useState([]);
  const tableRef = useRef(null);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const flow = searchParams.get("flow");
    setFlow(flow);

    let queryParam = {};
    if (searchParams.get("source") == undefined && source == "healthonify") {
      if (flow) {
        if( flow=="fitnessCenter"){
          queryParam.enquiryFor = "fitnessCenter";
        }else{
          queryParam = { flow, specific: 1 };
          queryParams.current = queryParam;
        }
       

        getEnquiryHandler(0, queryParam);
      } else {
        queryParam.enquiryFor = "generalEnquiry";
        getEnquiryHandler(0, queryParam);
      }
    }
    localStorage.setItem("queryParam", JSON.stringify(queryParam));
    // getEnquiryHandler();
  }, [location.search]);

  const getEnquiryHandler = (page = 0, queryParam = {}) => {
    setIsLoading(true);
    let newOne = Object.assign({}, queryParam);
    console.log("queryParam",queryParam)
    newOne.page = 0;
    newOne.limit = 10;
    dispatch(generalEnquiry(newOne))
      .unwrap()
      .then((data) => {
        setIsLoading(false);
        setpage(page);
        setTotalCount(data?.user?.totalCount);
        const modifiedArray = data?.user?.data?.map((obj, key) => {
          let newObj = {
            // Modify key names
            "#": parseInt(page) * 10 + 1 + key,
            "User Details": `TicketNumber:${obj.ticketNumber}<br>Name: ${obj.name}<br> Email: ${obj.email}<br>Mobile Number: ${obj.contactNumber}`,
            Message: obj.message,
            Category: obj.category,
            "Date of Enquiry": `${Moment(obj?.created_at).format(
              "DD-MM-YYYY"
            )} At ${Moment(obj.created_at).format("hh:mm A")}`,
            Status: obj.status,
          };

          if (
            obj?.status?.toLowerCase().includes("initiated") ||
            obj?.status === "Interested"
          ) {
            newObj["Action"] = [
              <button
                key="edit"
                className="btn btn-warning"
                style={{ height: "25px", padding: "0 10px" }}
                onClick={(e) => {
                  e.preventDefault();
                  navigate(
                    `/ProcessEnquiry/${obj._id || null}?userId=${
                      obj.userId ? obj.userId : "guest"
                    }&ticketNumber=${obj.ticketNumber}&category=${obj.category}`
                  );
                }}
              >
                Update / Assign
              </button>,
            ];
          } else {
            newObj["Action"] = [
              <button
                key="edit"
                className="btn btn-success"
                style={{ height: "25px", padding: "0 10px" }}
                onClick={(e) => {
                  e.preventDefault();
                  navigate(
                    `/ViewDetails/${obj.ticketNumber || null}/${obj.flow}`
                  );
                }}
              >
                View Details
              </button>,
            ];
          }
          return newObj;
        });

        setRow(modifiedArray);

        const formattedData = data.user.data.map((e, index) => ({
          index,
          ...e,
        }));
      })
      .catch(({ message }) => {
        setIsLoading(false);
        alert(message);
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let selectedSource = e.target.value;
    setSource(selectedSource);

    const searchParams = new URLSearchParams(location.search);
    const currentFlow = searchParams.get("flow");
    if (selectedSource === "lifespan") {
      searchParams.set("source", "lifespan");
      let newQueryParams = {};
      if (currentFlow) {
        newQueryParams = {
          source: selectedSource,
          flow: currentFlow,
          specific: 1,
        };
      } else {
        newQueryParams = {
          source: selectedSource,
          enquiryFor: "generalEnquiry",
        };
      }
      navigate({
        pathname: location.pathname,
        search: searchParams.toString(),
      });
      console.log(newQueryParams);
      getEnquiryHandler(0, newQueryParams);
    } else if (selectedSource === "healthonify") {
      if (currentFlow) {
        navigate(`/Enquiry?flow=${currentFlow}&specific=1`);
        getEnquiryHandler(0, { flow: currentFlow });
      } else {
        navigate(`/Enquiry`);
        getEnquiryHandler(0, {});
      }
    }
  };


  return (
    <div>
      <Navbarside />
      {isLoading && <Loader />}

      <div className="container-fluid pb-5 response-cover">
        <div className="row">
          <div className="col-lg-2 col-md-4" />
          <div className="col-lg-10 col-md-8">
            <div className="container-fluid pt-5">
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-header">
                      <div className="card-title-wrap bar-success d-flex align-items-center">
                        <h4 className="card-title text-capitalize">
                          {flow ? flow : "General"} Enquiries
                        </h4>{" "}
                        &nbsp; &nbsp; &nbsp;
                        {/* <ExportToExcel apiData={data} fileName={fileName} /> */}
                        <div className="col-md-2 col-sm-12 pt-2 ">
                          <button
                            onClick={() => {
                              excelDownload(row,`${flow ? flow.toUpperCase() : "General"}Enquiry.xlsx` );
                            }}
                            className="btn btn-primary"
                          >
                            Export
                          </button>
                        </div>
                      </div>
                    </div>
                    <section className="form-section">
                      <form>
                        <div className="row">
                          <div className="col-md-4">
                            <select
                              name="health"
                              id="health"
                              className="form-control"
                              onChange={handleSubmit}
                              value={source}
                            >
                              <option value="" className="" disabled>
                                Select Source
                              </option>
                              <option value={"healthonify"}>Healthonify</option>
                              <option value={"lifespan"}>Life Span</option>
                            </select>
                          </div>
                        </div>
                      </form>
                    </section>

                    <div className="card-body collapse show">
                      {row?.length > 0 && (
                        <div className="card-block card-dashboard table-responsive">
                          {/* <table id="example" className="display"></table> */}
                          <Table
                            columns={column}
                            data={row}
                            isFooter={true}
                            tableRef={tableRef}
                          />
                          <div className="PaginationContainer">
                            <span className="total-elements">
                              Showing {parseInt(page) * 10 + 1} to{" "}
                              {parseInt(page) * 10 + 10} of {totalCount} entries
                            </span>
                            <Pagination
                              currentPage={parseInt(page) + 1}
                              totalCount={totalCount}
                              itemsPerPage={10}
                              onPageChange={(page) => {
                                getEnquiryHandler(parseInt(page) - 1);
                              }}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default GeneralEnquiry;
