import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import AuthService from "../../services/auth.service";
import PackageService from './../../services/package.service';

export const getPackage = createAsyncThunk(
  "package/get/package",
  async (thunkAPI) => {
    try {
      const data = await PackageService.getPackages();
      return { user: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);


export const DeletePackage = createAsyncThunk(
  "blog/delete/blog",
  async (id) => {
    console.log("delete",id)
    try {
      const data = await PackageService.deletePackage(id);
      return { user: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return  message;
    }
  }
);

export const postPackage = createAsyncThunk(
  "package/post/package",
  async (item, thunkAPI) => {
    try {
      const data = await PackageService.postPackages(item);
      return { user: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);

// export const logout = createAsyncThunk("auth/logout", async () => {
//   AuthService.logout();
// });

const initialState = {
  loading: false,
  error: "",
  user: AuthService.getUserDetails() || null,
  isLoggedIn: false,
};

const packageSlice = createSlice({
  name: "package",
  initialState,
  extraReducers: {
    [getPackage.pending]: (state) => {
      state.loading = true;
      state.error = "";
      state.isLoggedIn = false;
      state.user = null;
    },
    [getPackage.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = "";
      state.isLoggedIn = true;
      state.user = action.payload.user;
    },
    [getPackage.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
      state.isLoggedIn = false;
      state.user = null;
    },

    [postPackage.pending]: (state) => {
      state.loading = true;
      state.error = "";
      state.isLoggedIn = false;
      state.user = null;
    },
    [postPackage.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = "";
      state.isLoggedIn = true;
      state.user = action.payload.user;
    },
    [postPackage.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
      state.isLoggedIn = false;
      state.user = null;
    },

    // [logout.fulfilled]: (state, action) => {
    //   state.isLoggedIn = false;
    //   state.user = null;
    // },
  },
});

const { reducer } = packageSlice;
export default reducer;