import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Navbarside from './Navbarside';
import { useNavigate, useParams } from 'react-router-dom';
import Footer from './footer';
import axios from 'axios';
import { getfitcenterDetails } from '../store/slices/fitness';
import { API_PATHS } from '../utils/constants/api.constants';

const EditFitnessCenter = () => {
  const navigate = useNavigate('');
  const { id } = useParams();

  const [category, setCategory] = useState([]);
  const [amenity, setAmenity] = useState([]);
  const [fcName, setFcName] = useState('');
  const [fcEmail, setFcEmail] = useState('');
  const [fcMobileNo, setFcMobileNo] = useState('');
  const [agreement, setAgreement] = useState('');
  const [logo, setLogo] = useState('');
  const [about, setAbout] = useState('');
  const [mediaLink, setmediaLink] = useState([]);
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [pincode, setPincode] = useState('');
  const [doorNoAndStreetName, setDoorNoAndStreetName] = useState('');

  const [alertfcName, setAlertFcName] = useState('');
  const [alertfcEmail, setAlertFcEmail] = useState('');
  const [alertfcMobileNo, setAlertFcMobileNo] = useState('');
  const [alertagreement, setAlertAgreement] = useState('');
  const [alertlogo, setAlertLogo] = useState('');
  const [alertabout, setAlertAbout] = useState('');

  const [alertcity, setAlertCity] = useState('');
  const [alertstate, setAlertState] = useState('');
  const [alertpincode, setAlertPincode] = useState('');
  const [alertdoorNoAndStreetName, setAlertDoorNoAndStreetName] = useState('');
  const [categoryData, setCategoryData] = useState([]); // State for fitness services
  const [amenityData, setAmenityData] = useState([]);

  const [location] = useState({
    type: 'Point',
    coordinates: [12.327608, 76.611868],
  });
  const [message, setMessage] = useState('');
  const [message2, setMessage2] = useState('');
  const dispatch = useDispatch();

  const validateFields = (e) => {
    let isValid = true;

    // Fitness Center Name Validation
    const regname = /^(([A-Za-z]+[,.]?[ ]?|[a-z]+['-]?|[0-9])+)$/;
    if (!regname.test(fcName)) {
      setAlertFcName(fcName ? 'Please enter a valid name' : 'Please enter the fitness center name');
      isValid = false;
    } else {
      setAlertFcName('');
    }

    // City Validation
    if (!regname.test(city)) {
      setAlertCity(city ? 'Please enter a valid city name' : 'Please enter the city');
      isValid = false;
    } else {
      setAlertCity('');
    }

    // State Validation
    if (!regname.test(state)) {
      setAlertState(state ? 'Please enter a valid state name' : 'Please enter the state');
      isValid = false;
    } else {
      setAlertState('');
    }

    // Email Validation
    const emailrgex = /[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,8}(.[a-z{2-8}])?/g;
    if (!emailrgex.test(fcEmail)) {
      setAlertFcEmail(fcEmail ? 'Please enter a valid email' : 'Please enter the fitness center email');
      isValid = false;
    } else {
      setAlertFcEmail('');
    }

    // Mobile Validation
    const mobile = /^(\+\d{1,3}[- ]?)?\d{10}$/;
    if (!mobile.test(fcMobileNo)) {
      setAlertFcMobileNo(fcMobileNo ? 'Please enter a valid mobile number' : 'Please enter the mobile number');
      isValid = false;
    } else {
      setAlertFcMobileNo('');
    }

    // Pincode Validation
    const pinregex = /^\d{6}$/; // Fixed validation for a 6-digit pincode
    if (!pinregex.test(pincode)) {
      setAlertPincode(pincode ? 'Please enter a valid pincode' : 'Please enter the pincode');
      isValid = false;
    } else {
      setAlertPincode('');
    }

    // About Validation
    const regdescription = /^(.|\s)*[a-zA-Z]+(.|\s)*$/;
    if (!regdescription.test(about)) {
      setAlertAbout(about ? 'Invalid description' : 'Please enter about the fitness center');
      isValid = false;
    } else {
      setAlertAbout('');
    }

    // Door Number and Street Name Validation
    if (!regdescription.test(doorNoAndStreetName)) {
      setAlertDoorNoAndStreetName(doorNoAndStreetName ? 'Invalid address' : 'Please enter the address');
      isValid = false;
    } else {
      setAlertDoorNoAndStreetName('');
    }

    // Agreement File Validation
    const regimage = /(pdf|txt|docx|doc|gif|jpe?g|tiff?|png|webp|bmp)$/i;
    if (!regimage.test(agreement)) {
      setAlertAgreement(agreement ? 'Invalid file' : 'Please upload the agreement');
      isValid = false;
    } else {
      setAlertAgreement('');
    }

    // Logo Validation
    const regcheque = /(gif|jpe?g|tiff?|png|webp|bmp)$/i;
    if (!regcheque.test(logo)) {
      setAlertLogo(logo ? 'Invalid image' : 'Please enter the image link');
      isValid = false;
    } else {
      setAlertLogo('');
    }

    if (!isValid) e.preventDefault();
    return isValid;
  };

  const save = (e) => {
    if (validateFields(e)) {
      addData(e); // Proceed if validation passes
    }
  };

  const fetchData = async () => {
    try {
      // Use axios.all to make parallel requests
      const [fitnessServicesResponse, amenitiesResponse] = await axios.all([
        axios.get('https://api.healthonify.com/get/fitnessServices'),
        axios.get('https://api.healthonify.com/get/fitnessCenterAmenities'),
      ]);

      // Set the state with the fetched data
      setCategoryData(fitnessServicesResponse?.data?.data); // Set fitness services data
      setAmenityData(amenitiesResponse?.data?.data); // Set amenities data
    } catch (error) {
      console.error('Error fetching data:', error.message);
    }
  };

  // useEffect hook to fetch both services and amenities when the component mounts
  useEffect(() => {
    fetchData();
  }, []);

  async function addData(e) {
    e.preventDefault();

    const fcAdminId = JSON.parse(localStorage.getItem('user')).data.id;

    const fcAddress = { city, state, pincode, doorNoAndStreetName };

    const set = {
      fcAdminId,
      fcName,
      fcEmail,
      fcMobileNo,
      agreement,
      logo,
      about,
      fcAddress,
      location,
      fitnessServices: category,
      amenities: amenity, // Already managed through `handleTagCheck`
      mediaLink,
    };
    console.log('set', set);

    let payload = {
      set,
    };

    try {
      const response = await axios.put(`https://api-wm.healthonify.com/put/fitnessCenter?id=${id}`, payload);

      if (response.status === 200) {
        alert('Added successfully');
        navigate(-1); // Navigate to the previous page
      } else {
        throw new Error(response.data.message || 'Failed to add the fitness center');
      }
    } catch (error) {
      alert(error.response?.data?.message || error.message || 'Something went wrong');
    }
  }

  const handleCheck = (event) => {
    const value = event.target.value; // This is the ID value
  
    const updatedCategory = event.target.checked
      ? category?.some((item) => item._id === value) // Check if the item is already in the array by comparing _id
        ? category // If the item is already present, don't add it
        : [...category, { _id: value }] // Add the value as an object with _id if not already in the array
      : category?.filter((item) => item._id !== value); // Remove the value if unchecked
    setCategory(updatedCategory); // Update the category state with the new array
  };
  
  
  
  const handleTagCheck = (event) => {
    const value = event.target.value; // This is the ID value
  
    const updatedAmenity = event.target.checked
      ? amenity?.some((item) => item._id === value) // Check if the item is already in the array by comparing _id
        ? amenity // If the item is already present, don't add it
        : [...amenity, { _id: value }] // Add the value as an object with _id if not already in the array
      : amenity?.filter((item) => item._id !== value); // Remove the value if unchecked
  
    setAmenity(updatedAmenity); // Update the amenity state with the new array
  };
  
  
  

  React.useEffect(() => {
    dispatch(getfitcenterDetails())
      .unwrap()
      .then((data) => {
        console.log('new data', data);
        if (data.user.data[0]) {
          // setfcId(data.user.data[0]._id)
          setFcName(data.user.data[0].fcName);
          setFcEmail(data.user.data[0].fcEmail);
          setFcMobileNo(data.user.data[0].fcMobileNo);
          setCity(data.user.data[0].fcAddress.city);
          setState(data.user.data[0].fcAddress.state);
          setAbout(data.user.data[0].about);
          setLogo(data.user.data[0].logo);
          setCategory(data.user.data[0].fitnessServices);
          setAmenity(data.user.data[0].amenities);
          setPincode(data.user.data[0].fcAddress.pincode);
          setDoorNoAndStreetName(data.user.data[0].fcAddress.doorNoAndStreetName);
          setAgreement(data.user.data[0].agreement);
          setmediaLink(data.user.data[0].mediaLink);
        }
      })
      .catch(({ message }) => {
        // alert(message);
      });
  }, []);

  async function aggrementpdf(e) {
    e.preventDefault();
    var formdata = new FormData();
    formdata.append('file', e.target.files[0]);

    var requestOptions = {
      method: 'POST',
      body: formdata,
      redirect: 'follow',
    };
    let response = await fetch(API_PATHS.uploadImage, requestOptions);
    let data = await response.json();
    setAgreement(data.data.location);
    setMessage(data.message);
  }

  async function imageUpload(e) {
    e.preventDefault();
    var formdata = new FormData();
    formdata.append('file', e.target.files[0]);

    var requestOptions = {
      method: 'POST',
      body: formdata,
      redirect: 'follow',
    };
    let response = await fetch(API_PATHS.uploadImage, requestOptions);
    let data = await response.json();
    setLogo(data.data.location);
    setMessage2(data.message);
  }

  async function fitnesscenterimageUpload(e) {
    e.preventDefault();
    var formdata = new FormData();
    const files = [];
    files.push(...e.target.files);
    files.forEach((file) => formdata.append('file', file));
    var requestOptions = {
      method: 'POST',
      body: formdata,
      redirect: 'follow',
    };
    let response = await fetch(API_PATHS.multiUploadImage, requestOptions);
    let data = await response.json();
    let imgurl = [];
    for (let i = 0; i < data.data.length; i++) {
      imgurl.push(data.data[i]);
    }
    setmediaLink(imgurl);
    setMessage(data.message);
  }

  const cancel = () => {
    setFcName('');
    setFcEmail('');
    setFcMobileNo('');
    setAgreement('');
    setLogo('');
    setAbout('');
    setCity('');
    setState('');
    setPincode('');
    setDoorNoAndStreetName('');
    setAlertFcName('');
    setAlertFcEmail('');
    setAlertFcMobileNo('');
    setAlertAgreement('');
    setAlertLogo('');
    setAlertAbout('');
    setAlertCity('');
    setAlertState('');
    setAlertPincode('');
    setAlertDoorNoAndStreetName('');
    setMessage('');
    setMessage2('');
  };


  return (
    <body data-col="2-columns" className=" 2-columns ">
      <Navbarside />

      <div className="container-fluid pb-5 response-cover">
        <div className="row">
          <div className="col-lg-2 col-md-4" />
          <div className="col-lg-10 col-md-8">
            <div className="container-fluid pt-5">
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-header">
                      <div className="card-title-wrap bar-danger d-flex align-items-center">
                        <h4 className="card-title">
                          Edit Fitness Center
                          {/* <Link
                              to="/UploadPackage"
                              className="btn btn-primary"
                            >
                              <i className="fa fa-plus"></i> Add
                            </Link> */}
                        </h4>
                      </div>
                    </div>

                    <section className="form-section">
                      <form onSubmit={addData}>
                        <div className="row">
                          <div className="col-md-6 col-sm-12 pt-2">
                            <p className="col-black" style={{ marginBottom: '2px' }}>
                              Fitness Center Name
                            </p>
                            <input type="text" placeholder="Enter the fitness center name" value={fcName} onChange={(e) => setFcName(e.target.value)} className="form-control" />
                            <p className="alert-message">{alertfcName}</p>
                          </div>

                          <div className="col-md-6 col-sm-12 pt-2">
                            <p className="col-black" style={{ marginBottom: '2px' }}>
                              Email
                            </p>
                            <input type="text" placeholder="Enter the fitness center email" value={fcEmail} onChange={(e) => setFcEmail(e.target.value)} className="form-control" />
                            <p className="alert-message">{alertfcEmail}</p>
                          </div>

                          <div className="col-md-6 col-sm-12 pt-2">
                            <p className="col-black" style={{ marginBottom: '2px' }}>
                              Mobile No.
                            </p>
                            <input type="number" placeholder="Enter the contact number" value={fcMobileNo} onChange={(e) => setFcMobileNo(e.target.value)} className="form-control" />
                            <p className="alert-message">{alertfcMobileNo}</p>
                          </div>

                          <div className="col-md-6 col-sm-12 pt-2">
                            <p className="col-black" style={{ marginBottom: '2px' }}>
                              About{' '}
                            </p>
                            <input type="text" placeholder="Enter about your fitness center" value={about} onChange={(e) => setAbout(e.target.value)} className="form-control" />
                            <p className="alert-message">{alertabout}</p>
                          </div>

                          <div className="col-md-6 col-sm-12 pt-2">
                            <p className="col-black" style={{ marginBottom: '2px' }}>
                              City
                            </p>
                            <input type="text" placeholder="Enter the city " value={city} onChange={(e) => setCity(e.target.value)} className="form-control" />
                            <p className="alert-message">{alertcity}</p>
                          </div>

                          <div className="col-md-6 col-sm-12 pt-2">
                            <p className="col-black" style={{ marginBottom: '2px' }}>
                              State
                            </p>
                            <input type="text" placeholder="Enter the state" value={state} onChange={(e) => setState(e.target.value)} className="form-control" />
                            <p className="alert-message">{alertstate}</p>
                          </div>

                          <div className="col-md-6 col-sm-12 pt-2">
                            <p className="col-black" style={{ marginBottom: '2px' }}>
                              Pincode
                            </p>
                            <input type="number" placeholder="Enter the pincode" value={pincode} onChange={(e) => setPincode(e.target.value)} className="form-control" />
                            <p className="alert-message">{alertpincode}</p>
                          </div>

                          <div className="col-md-6 col-sm-12 pt-2">
                            <p className="col-black" style={{ marginBottom: '2px' }}>
                              Address
                            </p>
                            <input type="text" placeholder="Enter address" value={doorNoAndStreetName} onChange={(e) => setDoorNoAndStreetName(e.target.value)} className="form-control" />
                            <p className="alert-message">{alertdoorNoAndStreetName}</p>
                          </div>

                          <div className="col-md-6 col-sm-12 pt-2">
                            <p className="col-black" style={{ marginBottom: '2px' }}>
                              Logo
                            </p>
                            <input type="file" className="form-control-file" Placeholder="Price Tagline" required onChange={(e) => imageUpload(e)} />
                            <p className="alert-message">{alertlogo}</p>
                            <p style={{ color: 'green', fontWeight: '500' }}>{message2}</p>
                          </div>

                          <div className="col-md-6 col-sm-12 pt-2">
                            <p className="col-black" style={{ marginBottom: '2px' }}>
                              Agreement
                            </p>
                            <input type="file" className="form-control-file" Placeholder="Price Tagline" required onChange={(e) => aggrementpdf(e)} />
                            <p className="alert-message">{alertagreement}</p>
                            <p style={{ color: 'green', fontWeight: '500' }}>{message}</p>
                          </div>
                          <div className="col-6">
                            <p className="col-black">Fitness Center Images (Max 10 images and resolution 1920X1080)</p>
                            <input type="File" name="mediaLink" multiple onChange={(e) => fitnesscenterimageUpload(e)} id="inputmediaLink" className="form-control-file" required />
                            <br></br>
                            <p>{message}</p>
                          </div>

                          <div className="col-12 mt-3">
                            <p className="col-black">Services Provided</p>
                          </div>

                          {categoryData?.map((LabVal, index) => (
                            <div className=" col-lg-6 col-md-6 col-sm-12">
                              <input type="checkbox" name="options" value={LabVal._id} onChange={handleCheck} checked={category?.find((item) => item._id === LabVal._id) !== undefined} />
                              &nbsp;
                              {LabVal.name}
                            </div>
                          ))}

                          <div className="col-12 mt-4">
                            <p className="col-black">Amenities</p>
                          </div>

                          {amenityData?.map((AmenityVal, index) => (
                            <div className=" col-lg-6 col-md-6 col-sm-12">
                              <input type="checkbox" name="options" value={AmenityVal._id} onChange={handleTagCheck} checked={amenity?.find((item) => item._id === AmenityVal._id) !== undefined} />
                              &nbsp;
                              {AmenityVal.name}
                            </div>
                          ))}
                        </div>
                        <div className="form-actions center">
                          <button type="submit" className="btn btn-success" onClick={save}>
                            Submit
                          </button>
                          &nbsp; &nbsp; &nbsp;
                          <button type="reset" className="btn btn-warning mt-2" onClick={cancel}>
                            Cancel
                          </button>
                        </div>
                      </form>
                    </section>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </body>
  );
};

export default EditFitnessCenter;
