import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { FcSettings } from "react-icons/fc";
import {
  FaBuromobelexperte,
  FaWeight,
  FaHandHoldingHeart,
  FaBriefcase,
  FaUserNurse,
} from "react-icons/fa";
import { RiMentalHealthFill } from "react-icons/ri";
import { IoPersonCircle } from "react-icons/io5";
import { BiTestTube, BiAnalyse, BiPlanet } from "react-icons/bi";
import {
  MdHealthAndSafety,
  MdOutlineLogout,
  MdMeetingRoom,
  MdOutlineAccountBalance,
  MdAssessment,
} from "react-icons/md";
import { CgCommunity } from "react-icons/cg";
import { ImLab, ImProfile } from "react-icons/im";

import {
  GiTeapotLeaves,
  GiTakeMyMoney,
  GiTestTubes,
  GiTransparentTubes,
} from "react-icons/gi";
import { GrBlog } from "react-icons/gr";
import { FcFaq } from "react-icons/fc";
import { BsQuestionDiamondFill } from "react-icons/bs";
import { RiHomeGearFill } from "react-icons/ri";
import { Link, useNavigate, NavLink } from "react-router-dom";
import logo from "../img/healthonify-logo.png";
import { userDetails } from "./../store/slices/auth";
const Navbarside = () => {
  const dispatch = useDispatch();

  const open = () => {
    document.getElementById("side").classList.toggle("show");
  };

  const drop = () => {
    document.getElementById("usermenu").classList.toggle("showuser");
  };

  let data = JSON.parse(localStorage.getItem("user"));
  let role = data?.data?.roles[0];

  const navigate = useNavigate();

  function signOut(e) {
    localStorage.clear();
    navigate("/login");
  }
  const [details, setDetails] = useState("");
  const [topExpertise, settopExpertise] = useState("");
  React.useEffect(() => {
    dispatch(userDetails())
      .unwrap()
      .then((data) => {
        setDetails(data.user.data[0]);
        settopExpertise(data.user.data[0].topExpertise.name);
      })
      .catch(({ message }) => {
        // alert(message);
      });
  }, [dispatch]);

  return (
    <div>
      <nav className="top_nav">
        <FcSettings className="setting animate-rotate " onClick={open} />
        <IoPersonCircle className="user-icon" onClick={drop} />
      </nav>

      <nav className="sidebar" id="side">
        <NavLink to="/Home">
          <div className="d-flex justify-content-center logo-section">
            <img src={logo} alt="healthonify" />
          </div>
        </NavLink>
        <ul className="nav-lists pt-1">
          <li className="nav_items top_item">
            <NavLink to="/Home" className="menu-title" activeClassName="active">
              <RiHomeGearFill className="nav_icons" />
              Dashboard
            </NavLink>
          </li>
          {/* super admin  menu start*/}
          {/* {role === "ROLE_SUPERADMIN" && (
            <li className="nav_items">
              <a href="/Enquiry" className="menu-title">
                <i className="far fa-comments fa-edit-icon"></i>&nbsp; General
                Enquiry
              </a>
            </li>
          )} */}

          {role === "ROLE_SUPERADMIN" && (
            <li className="nav_items">
              <NavLink to="/Enquiry" className="menu-title">
                {/* <FaWeight className="nav_icons" /> */}
                <i className="far fa-comments fa-edit-icon"></i>&nbsp;
                General Enquiry
              </NavLink>
            </li>
          )}
          {role === "ROLE_SUPERADMIN" && (
            <li className="nav_items">
              <NavLink to="/Weightmanagement" className="menu-title">
                <FaWeight className="nav_icons" />
                Weight Management
              </NavLink>
            </li>
          )}
          {role === "ROLE_SUPERADMIN" && (
            <li className="nav_items">
              <NavLink to="/Fitness" className="menu-title">
                <FaHandHoldingHeart className="nav_icons" />
                Fitness
              </NavLink>
            </li>
          )}
          {role === "ROLE_SUPERADMIN" && (
            <li className="nav_items">
              <NavLink to="/HealthCare" className="menu-title">
                <MdHealthAndSafety className="nav_icons" />
                Health Care
              </NavLink>
            </li>
          )}
          {role === "ROLE_SUPERADMIN" && (
            <li className="nav_items">
              <NavLink to="/Challenges" className="menu-title">
                <MdHealthAndSafety className="nav_icons" />
                Challenges
              </NavLink>
            </li>
          )}
          {role === "ROLE_SUPERADMIN" && (
            <li className="nav_items">
              <NavLink to="/Physiotherapy" className="menu-title">
                <RiMentalHealthFill className="nav_icons" />
                Physiotherapy
              </NavLink>
            </li>
          )}
          {role === "ROLE_SUPERADMIN" && (
            <li className="nav_items">
              <NavLink to="/WomenSpecialSetting" className="menu-title">
                <RiMentalHealthFill className="nav_icons" />
                WomenSpecialSetting
              </NavLink>
            </li>
          )}
          {/* {role === "ROLE_SUPERADMIN" && (
            <li className="nav_items">
              <NavLink to="/Conditions" className="menu-title">
                <RiMentalHealthFill className="nav_icons" />
                Conditions
              </NavLink>
            </li>
          )} */}
          {role === "ROLE_SUPERADMIN" && (
            <li className="nav_items">
              <NavLink to="/educate" className="menu-title">
                <RiMentalHealthFill className="nav_icons" />
                Educate
              </NavLink>
            </li>
          )}
          {role === "ROLE_SUPERADMIN" && (
            <li className="nav_items">
              <NavLink to="/Experts" className="menu-title">
                <RiMentalHealthFill className="nav_icons" />
                Experts
              </NavLink>
            </li>
          )}
          {role === "ROLE_SUPERADMIN" && (
            <li className="nav_items">
              <NavLink to="/Corporate" className="menu-title">
                <FaBriefcase className="nav_icons" />
                Corporate
              </NavLink>
            </li>
          )}
          {role === "ROLE_SUPERADMIN" && (
            <li className="nav_items">
              <NavLink to="/Analysis" className="menu-title">
                <BiAnalyse className="nav_icons" />
                Analysis
              </NavLink>
            </li>
          )}
          {role === "ROLE_SUPERADMIN" && (
            <li className="nav_items">
              <NavLink to="/Accounts" className="menu-title">
                <MdOutlineAccountBalance className="nav_icons" />
                Accounts
              </NavLink>
            </li>
          )}
          {role === "ROLE_SUPERADMIN" && (
            <li className="nav_items">
              <NavLink to="/Labs" className="menu-title">
                <ImLab className="nav_icons" />
                Labs
              </NavLink>
            </li>
          )}

          {role === "ROLE_SUPERADMIN" && (
            <li className="nav_items">
              <NavLink to="/LiveWell" className="menu-title">
                <GiTeapotLeaves className="nav_icons" />
                Live Well
              </NavLink>
            </li>
          )}
          {role === "ROLE_SUPERADMIN" && (
            <li className="nav_items">
              <NavLink to="/LifeSpan" className="menu-title">
                <GiTeapotLeaves className="nav_icons" />
                Life Span
              </NavLink>
            </li>
          )}

          {role === "ROLE_SUPERADMIN" && (
            <li className="nav_items">
              <NavLink to="/Questionnaire" className="menu-title ">
                <MdAssessment className="nav_icons" />
                HRA Question
              </NavLink>
            </li>
          )}

          {role === "ROLE_SUPERADMIN" && (
            <li className="nav_items">
              <NavLink to="/Faq" className="menu-title ">
                <FcFaq className="nav_icons" />
                FAQs
              </NavLink>
            </li>
          )}
          {role === "ROLE_SUPERADMIN" && (
            <li className="nav_items">
              <NavLink to="/Blog" className="menu-title">
                <GrBlog className="nav_icons" />
                Blog
              </NavLink>
            </li>
          )}
          {role === "ROLE_SUPERADMIN" && (
            <li className="nav_items">
              <NavLink to="/Community" className="menu-title ">
                <CgCommunity className="nav_icons" />
                Community
              </NavLink>
            </li>
          )}
          {role === "ROLE_SUPERADMIN" && (
            <li className="nav_items">
              <NavLink to="/Package" className="menu-title">
                <BiPlanet className="nav_icons" />
                Package
              </NavLink>
            </li>
          )}
          {/*Lab vendor*/}
          {role === "ROLE_LABVENDOR" && (
            <li className="nav_items">
              <NavLink to="/MyLabs" className="menu-title">
                <GiTestTubes className="nav_icons" />
                My Labs
              </NavLink>
            </li>
          )}
          {role === "ROLE_LABVENDOR" && (
            <li className="nav_items">
              <NavLink to="/Labtest" className="menu-title">
                <GiTransparentTubes className="nav_icons" />
                Lab Test
              </NavLink>
            </li>
          )}
          {role === "ROLE_LABVENDOR" && (
            <li className="nav_items">
              <NavLink to="/LabVendorRevenue" className="menu-title">
                <BiTestTube className="nav_icons" />
                Revenue
              </NavLink>
            </li>
          )}

          {topExpertise === "Health Care" && (
            <li className="nav_items">
              <NavLink to="/DoctorAppointment" className="menu-title ">
                <MdMeetingRoom className="nav_icons" />
                My Appointments
              </NavLink>
            </li>
          )}
          {topExpertise === "Health Care" && (
            <li className="nav_items">
              <NavLink to="/TodayAppointment" className="menu-title ">
                <FaUserNurse className="nav_icons" />
                Prescription
              </NavLink>
            </li>
          )}

          {topExpertise === "Health Care" && (
            <li className="nav_items">
              <NavLink to="/MyPatient" className="menu-title ">
                <FaUserNurse className="nav_icons" />
                My Patient
              </NavLink>
            </li>
          )}

          {topExpertise === "Dietitian" && (
            <li className="nav_items">
              <NavLink to="/MyClients" className="menu-title ">
                <FaUserNurse className="nav_icons" />
                My Clients
              </NavLink>
            </li>
          )}
          {topExpertise === "Dietitian" && (
            <li className="nav_items">
              <NavLink to="/WmAppointments" className="menu-title ">
                <FaUserNurse className="nav_icons" />
                My Appointments
              </NavLink>
            </li>
          )}
          {topExpertise === "Dietitian" && (
            <li className="nav_items">
              <NavLink to="/WmExpertSession" className="menu-title ">
                <FaUserNurse className="nav_icons" />
                My Sessions
              </NavLink>
            </li>
          )}
          {topExpertise === "Dietitian" && (
            <li className="nav_items">
              <NavLink to="/ExpertRecipes" className="menu-title ">
                <GiTakeMyMoney className="nav_icons" />
                Recipe
              </NavLink>
            </li>
          )}

          {topExpertise === "Physiotherapy" && (
            <li className="nav_items">
              <NavLink to="/MyClients" className="menu-title ">
                <FaUserNurse className="nav_icons" />
                My Clients
              </NavLink>
            </li>
          )}

          {role === "ROLE_EXPERT" && (
            <li className="nav_items">
              <NavLink to="/Revenue" className="menu-title ">
                <GiTakeMyMoney className="nav_icons" />
                My Revenue
              </NavLink>
            </li>
          )}
          {topExpertise === "Dietitian" && (
            <li className="nav_items">
              <NavLink to="/ExpertWorkoutplans" className="menu-title ">
                <GiTakeMyMoney className="nav_icons" />
                Workout Plan
              </NavLink>
            </li>
          )}

          {topExpertise === "Physiotherapy" && (
            <li className="nav_items">
              <NavLink to="/Fitness/Exercise" className="menu-title ">
                <GiTakeMyMoney className="nav_icons" />
                Exercise
              </NavLink>
            </li>
          )}
          {topExpertise === "Dietitian" && (
            <li className="nav_items">
              <NavLink to="/Fitness/Exercise" className="menu-title ">
                <GiTakeMyMoney className="nav_icons" />
                Exercise
              </NavLink>
            </li>
          )}
          {topExpertise === "Dietitian" && (
            <li className="nav_items">
              <NavLink to="/ExpertDietPlans" className="menu-title ">
                <GiTakeMyMoney className="nav_icons" />
                Diet Plan
              </NavLink>
            </li>
          )}

          {role === "ROLE_EXPERT" && (
            <li className="nav_items">
              <NavLink to="/ExpertCommunity" className="menu-title ">
                <CgCommunity className="nav_icons" />
                Community
              </NavLink>
            </li>
          )}

          {role === "ROLE_EXPERT" && (
            <li className="nav_items">
              <NavLink to="/Profile" className="menu-title">
                <ImProfile className="nav_icons" />
                My Profile
              </NavLink>
            </li>
          )}
          {/* fitness center admin starts*/}
          {role === "ROLE_FITNESSCENTERADMIN" && (
            <li className="nav_items">
              <NavLink to="/MyFitnessCenter" className="menu-title">
                <GiTestTubes className="nav_icons" />
                My Fitness Centers
              </NavLink>
            </li>
          )}
          {role === "ROLE_FITNESSCENTERADMIN" && (
            <li className="nav_items">
              <NavLink to="/FitnessRevenue" className="menu-title">
                <BiTestTube className="nav_icons" />
                Revenue
              </NavLink>
            </li>
          )}
          {role === "ROLE_FITNESSCENTERADMIN" && (
            <li className="nav_items">
              <NavLink to="/SessionAnalysis?role=fitnessCenterAdmin&fromDate" className="menu-title">
                <BiTestTube className="nav_icons" />
                Sessions
              </NavLink>
            </li>
          )}
          {role === "ROLE_FITNESSCENTERADMIN" && (
            <li className="nav_items">
              <NavLink to="/FitnessPlans" className="menu-title">
                <BiPlanet className="nav_icons" />
                Plans
              </NavLink>
            </li>
          )}
          {/* fitness center plan ends */}
          <li className="nav_items">
            <Link to="/Login" onClick={signOut} className="menu-title ">
              <MdOutlineLogout className="nav_icons" />
              Logout
            </Link>
          </li>
        </ul>
      </nav>
      <div className="drop-user" id="usermenu">
        <ul className="user-p">
          <li className="user-text">
            <NavLink to="/profile">
              <FaBuromobelexperte className="user-icon-style-mod" />
              My Profile
            </NavLink>
          </li>

          <li className="user-text">
            <Link to="/Login" onClick={signOut}>
              <MdOutlineLogout className="user-icon-style-mod" />
              Logout
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Navbarside;
