import axios from "axios";
import { API_PATHS } from "../utils/constants/api.constants";

class fitnesscenterService {
    static getfitnesscenterRegister(params) {
        let api = '';
        let id = localStorage.getItem("id");
        api = id ? API_PATHS.getfitness + '?_id=' + id : API_PATHS.getfitness;
        localStorage.removeItem("id");
        return axios
            .get(api, {params})
            .then((response) => {
                if (response.data) {
                    // localStorage.setItem("user", JSON.stringify(response.data));
                }
                return response.data;
            });
    }

    static getfitnesscenterrequest() {
        let api = '';
        let id = localStorage.getItem("id");
        api = id ? API_PATHS.getfitnessrequest + '?_id=' + id : API_PATHS.getfitnessrequest;
        localStorage.removeItem("id");
        return axios
            .get(api)
            .then((response) => {
                if (response.data) {
                    // localStorage.setItem("user", JSON.stringify(response.data));
                }
                return response.data;
            });
    }

    static getfitnesscenters() {
        let api = '';
        api =  API_PATHS.getfitnesscenter;
        return axios
            .get(api)
            .then((response) => {
                if (response.data) {
                    // localStorage.setItem("user", JSON.stringify(response.data));
                }
                return response.data;
            });
    }

    static getfitnesscenterDetails() {
        let api = '';
        let id = localStorage.getItem("fitnesscenterId");
        api =API_PATHS.getfitnesscenter + '?id=' + id ;
        localStorage.removeItem("id");
        return axios
            .get(api)
            .then((response) => {
                if (response.data) {
                    // localStorage.setItem("user", JSON.stringify(response.data));
                }
                return response.data;
            });
    }
    
    static getVendorfitnesscenters() {
        let api = '';
        let id = JSON.parse(localStorage.getItem("user")).data.id;
          api = API_PATHS.getfitnesscenter + '?fcAdminId=' + id;
     
        localStorage.removeItem("id");
        return axios
            .get(api)
            .then((response) => {
                if (response.data) {
                    // localStorage.setItem("user", JSON.stringify(response.data));
                }
                return response.data;
            });
    }

    static getfitnessService() {
        let api = '';
     
        api =API_PATHS.getfitnessService;
       
        return axios
            .get(api)
            .then((response) => {
                if (response.data) {
                    // localStorage.setItem("user", JSON.stringify(response.data));
                }
                return response.data;
            });
    }

    static getAmenties() {
        let api = '';
        api =API_PATHS.getfitnesscenterAmenities;
        return axios
            .get(api)
            .then((response) => {
                if (response.data) {
                    // localStorage.setItem("user", JSON.stringify(response.data));
                }
                return response.data;
            });
    }

    static postfitnesscenters(item) {
        let api='';
        api = API_PATHS.postfitnesscenter;
        return axios
          .post(api,item)
          .then((response) => {
            if (response.data) {
            
            }
            return response.data;
          });
      }

      
    static postfitnesscenterPlan(item) {
        let api='';
        api = API_PATHS.postFitnessPlan;
        return axios
          .post(api,item)
          .then((response) => {
            if (response.data) {
            
            }
            return response.data;
          });
      }

      static putfitnesscenterPlan() {
        let api='';
        let item = JSON.parse(localStorage.getItem("item"));
        let id = localStorage.getItem("id");
        api = API_PATHS.putFitnessPlan+  '?id=' + id ;
        return axios
          .put(api,item)
          .then((response) => {
            if (response.data) {
            
            }
            return response.data;
          });
      }

      static postAmenities(item) {
        let api='';
        api = API_PATHS.postfitnesscenterAmenities;
        return axios
          .post(api,item)
          .then((response) => {
            if (response.data) {
            
            }
            return response.data;
          });
      }

    static ApprovefitnessEnquiry(item) {
        let api='';
        api = API_PATHS.postapprovefitness;
        return axios
          .post(api,item)
          .then((response) => {
            if (response.data) {
            
            }
            return response.data;
          });
      }

      static ApprovefitnessRequest(item) {
        let api='';
        api = API_PATHS.postapproverequest;
        return axios
          .post(api,item)
          .then((response) => {
            if (response.data) {
            
            }
            return response.data;
          });
      }

  

    static removeUserDetails() {
        localStorage.removeItem("user");
    }

    static getUserDetails() {
        return JSON.parse(localStorage.getItem("user"));
    }
}

export default fitnesscenterService